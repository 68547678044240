import CompetitiveDiagnostic from "components/msd/CompetitiveDiagnostic/CompetitiveDiagnostic";
import CompetitiveEnvironment from "components/msd/CompetitiveEnvironment/CompetitiveEnvironment";
import CurrentMonthPerformance from "components/msd/CompetitivePositioning/CompetitivePositioning";
import FareImprovements from "components/msd/FareImprovements/FareImprovements";
import KPIMini from "components/msd/KPIMini/KPIMini";
import MarketProfile from "components/msd/MarketProfile/MarketProfile";
import RecommendedMarketActions from "components/msd/RecommendedMarketActions/RecommendedMarketActions";
import RecommendedStrategy from "components/msd/RecommendedStrategy/RecommendedStrategy";
import ShareAdjustments from "components/msd/ShareAdjustments/ShareAdjustments";
import { useState } from "react";
import Layout from "components/common/Layout/Layout";

export default function STRATEGYACTIONS() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [data, setData] = useState({
    market_details: {
      competitive_environment: [],
      market_profile: [],
    },
    metrics: {
      fare_gap: {
        title: "",
        value: "",
      },
      fare_variance: {
        title: "",
        value: "",
      },
      host_avg_fare: {
        title: "",
        value: "",
      },
      host_fair_share: {
        title: "",
        value: "",
      },
      host_pax: {
        title: "",
        value: "",
      },
      host_share: {
        title: "",
        value: "",
      },
      host_share_gap: {
        title: "",
        value: "",
      },
      industry_avg_fare: {
        title: "",
        value: "",
      },
      industry_pax: {
        title: "",
        value: "",
      },
      market_size: {
        title: "",
        value: "",
      },
    },
    revopt_fare_improvement: "",
    revopt_share_adjustment: "",
    strategy: {
      competitive_diagnostic: {
        title: "",
        value: [],
      },
      recommended_market_actions: {
        title: "",
        value: [],
      },
      recommended_strategy: {
        title: "",
        value: [],
      },
    },
  });
  const [storyText, setStoryText] = useState({
    comp_environ: {
      content: "",
      title: "",
    },
    curr_month_perf: {
      content: "",
      title: "",
    },
    market_profile: {
      content: "",
      title: "",
    },
    rec_market_actions: {
      content: "",
      title: "",
    },
  });
  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Strategy Actions"
    >
      <KPIMini filterIsOpen={filterIsOpen} />

      <div
        style={{
          display: "flex",
          gap: 8,
        }}
      >
        <CompetitiveDiagnostic data={data?.strategy?.competitive_diagnostic} />
        <RecommendedStrategy data={data?.strategy?.recommended_strategy} />
      </div>
      <div
        style={{
          display: "flex",
          gap: 8,
        }}
      >
        <MarketProfile
          data={data ? data?.market_details.market_profile : []}
          storyText={storyText}
        />
        <CompetitiveEnvironment
          data={data?.market_details.competitive_environment}
          storyText={storyText}
        />
      </div>

      <CurrentMonthPerformance
        filterIsOpen={filterIsOpen}
        data={data}
        setOtherDatas={setData}
        storyText={storyText}
        setStoryText={setStoryText}
      />

      <div
        style={{
          display: "flex",
          gap: 8,
        }}
      >
        <ShareAdjustments data={data?.revopt_share_adjustment} />
        <FareImprovements data={data?.revopt_fare_improvement} />
      </div>
      <RecommendedMarketActions
        data={data?.strategy?.recommended_market_actions}
        storyText={storyText}
      />
    </Layout>
  );
}
