import revenue from "../../../assets/icons/atom-icon-cpi-yellow.webp";
import rask from "../../../assets/icons/atom-icon-cpi-yellow.webp";
import capacity from "../../../assets/icons/atom-icon-cpi-blue@3x.webp";
import market_share from "../../../assets/icons/atom-icon-cpi-red@3x.webp";
import average_fare from "../../../assets/icons/atom-icon-cpi-navy-blue@3x.webp";
import passenger from "../../../assets/icons/atom-icon-cpi-magenta@3x.webp";
import cargo from "../../../assets/icons/atom-icon-cpi-purple@3x.webp";

export const kpiOptions = {
  passenger: {
    icon: passenger,
    iconColor: "#9c00ed33",
    lineColor: "#e323ff",
    lineColorGradient: ["#7600ff", "#cd70ff", "#e323ff"],
  },
  revenue: {
    icon: revenue,
    iconColor: "#da77061a",
    lineColor: "#ffa10a",
    lineColorGradient: ["#ff59c5", "#ff9b02", "#ffa10a"],
  },
  rask: {
    icon: rask,
    iconColor: "#da77061a",
    lineColor: "#ffa10a",
    lineColorGradient: ["#ff59c5", "#ff9b02", "#ffa10a"],
  },
  capacity: {
    icon: capacity,
    iconColor: "#259eff1a",
    lineColor: "#14a0ff",
    lineColorGradient: ["#14bdff", "#8ce1ff", "#14a0ff"],
  },
  cargo: {
    icon: cargo,
    iconColor: "#5504d933",
    lineColor: "#5004de",
    lineColorGradient: ["#6100ff", "#7f28ff", "#5004de"],
  },
  market_share: {
    icon: market_share,
    iconColor: "#ec42521a",
    lineColor: "#901931",
    lineColorGradient: ["#84023d", "#c84a7a", "#901931"],
  },
  average_fare: {
    icon: average_fare,
    iconColor: "#144aff33",
    lineColor: "#144aff",
    lineColorGradient: ["#1416ff", "#436eff", "#144aff"],
  },
};
