import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import images from "constans/images";
import { useContext } from "react";
import { ThemeContext } from "context-api/ThemeContext";

interface IFareImprovements {
  data: string;
}
export default function FareImprovements({ data }: IFareImprovements) {
  const { theme } = useContext(ThemeContext);
  return (
    <Card
      variant="secondary"
      width={"100%"}
      height={144}
      borderRadius="15px 15px 50px 15px"
      borderColor="#ff8f1f"
    >
      <div className={styles.container} data-theme={theme}>
        <span>Revenue Opportunity from Fare Improvements</span>
        <p>{data}</p>
        <span className={styles.icon_wrapper}>
          <img src={images.money_coint_growth} alt="" />
        </span>
      </div>
    </Card>
  );
}
