import { ReactNode, useContext } from "react";
import styles from "./styles.module.css";
import { ThemeContext } from "context-api/ThemeContext";
import { Tooltip } from "components/common/Tooltip";

type Data = {
  max_weekday: string;
  max_date: string;
  max_time: string;
  max_lf?: string;
  class: string;
  comp_fare: string;
  comp_flight_num: number;
  host_fare: string;
  host_flight_num: number;
  host_lf: string;
};

type Props = {
  children: ReactNode;
  data: Data;
  carrierColor?: string;
  isPriceEvo?: boolean;
};

export default function StatisticTooltip({
  children,
  data,
  carrierColor = "#3f365a",
  isPriceEvo = false,
}: Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      title={htmlElement(data, carrierColor, isPriceEvo)}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "transparent",
          },
        },
      }}
    >
      <span className={styles.children} data-theme={theme}>
        {children}
      </span>
    </Tooltip>
  );
}
const htmlElement = (data: Data, carrierColor, isPriceEvo) => {
  return (
    <div className={styles.container} style={{ backgroundColor: carrierColor }}>
      <span className={styles.wrapper}>
        <span className={styles.title}>
          {isPriceEvo ? "Days to Departure" : "Departure Date"}
        </span>
        :<span className={styles.description}>{data?.max_date ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Departure Time</span>:
        <span className={styles.description}>{data?.max_time ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Days of Week</span>:
        <span className={styles.description}>{data?.max_weekday ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Class</span>:
        <span className={styles.description}>{data?.class ?? "-"}</span>
      </span>
      {data?.max_lf && (
        <span className={styles.wrapper}>
          <span className={styles.title}>Load Factor</span>:
          <span className={styles.description}>{data?.max_lf ?? "-"}</span>
        </span>
      )}
      <span className={styles.wrapper}>
        <span className={styles.title}>Comp Fare</span>:
        <span className={styles.description}>{data?.comp_fare ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Comp Flight Number</span>:
        <span className={styles.description}>
          {data?.comp_flight_num ?? "-"}
        </span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Host Fare</span>:
        <span className={styles.description}>{data?.host_fare ?? "-"}</span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Host Flight Number</span>:
        <span className={styles.description}>
          {data?.host_flight_num ?? "-"}
        </span>
      </span>
      <span className={styles.wrapper}>
        <span className={styles.title}>Host Load Factor</span>:
        <span className={styles.description}>{data?.host_lf ?? "-"}</span>
      </span>
    </div>
  );
};
