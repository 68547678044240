/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import styles from "./RangeSlider.module.css";
import moment from "moment";
import { ThemeContext } from "context-api/ThemeContext";

interface IRangeSlider {
  data: {
    max: number;
    min: number;
    value: number | number[];
    setValue: any;
    dateList: any;
  };
  format?: string;
  disabled?: boolean;
}
interface IStyled {
  customtheme: string;
}
const CustomSlider = styled(Slider)(({ customtheme }: IStyled) => ({
  color: customtheme === "dark" ? "#4a4266" : "var(--bg-color-white)",
  height: 4,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    width: 64,
    height: 24,
    borderRadius: 5,
    backgroundColor: customtheme === "dark" ? "#4a4266" : "#cbbbef",
    "&:before": {
      boxShadow:
        customtheme === "dark" ? "0 2px 12px 0 rgba(0,0,0,0.4)" : "none",
    },
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${"transparent"}`,
    },
    "& .range-range-value": {
      color:
        customtheme === "dark" ? "#fff" : "var(--text-color-primary-light)",
      fontFamily: "var(--font-family-medium)",
      fontSize: 12,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
    "& .range-up-arrow": {
      border: "solid #fff",
      borderWidth: "0 1px 1px 0",
      padding: 2,
      transform: "rotate(-135deg)",
      display: "flex",
      position: "relative",
      top: 1,
      justifyContent: "center",
      alignItems: "center",
    },
  },
  "& .MuiSlider-track": {
    height: 4,
    color: customtheme === "dark" ? "#4a4266" : "#cbbbef",
  },
  "& .MuiSlider-rail": {
    color: customtheme === "dark" ? "#4a4266" : "rgba(85, 4, 217, 0.3)",
    height: 4,
  },
}));
interface CustomSliderThumbComponentProps
  extends React.HTMLAttributes<unknown> {}

export default function RangeSlider({
  data,
  format,
  disabled = false,
}: IRangeSlider) {
  const [value, setValue] = React.useState<number | number[]>(data.value);
  const { theme } = React.useContext(ThemeContext);

  React.useEffect(() => {
    setValue(data.value);
  }, [data.value]);

  const CustomSliderThumbComponent = (
    props: CustomSliderThumbComponentProps
  ) => {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <span className="range-range-value">
          {typeof value === "number" && data?.dateList?.length > 0
            ? moment(data.dateList[value])
                .format(format ? format : "MMM YY")
                .toUpperCase()
            : data.dateList.length < 1 && typeof value === "number"
            ? Math.abs(value)
            : other["data-index"] === 0
            ? format === "HH:mm"
              ? moment(data.dateList[value[0]], "hh")
                  .format(format ? format : "MMM YY")
                  .toUpperCase()
              : moment(data.dateList[value[0]])
                  .format(format ? format : "MMM YY")
                  .toUpperCase()
            : format === "HH:mm"
            ? moment(data.dateList[value[1]], "hh:mm")
                .format(format ? format : "MMM YY")
                .toUpperCase()
            : moment(data.dateList[value[1]])
                .format(format ? format : "MMM YY")
                .toUpperCase()}
        </span>
      </SliderThumb>
    );
  };
  return (
    <div
      className={styles.container}
      style={{ cursor: disabled ? "no-drop" : "auto" }}
    >
      <CustomSlider
        customtheme={theme}
        data-theme={theme}
        components={{ Thumb: CustomSliderThumbComponent }}
        getAriaLabel={(index) =>
          index === 0 ? "Minimum price" : "Maximum price"
        }
        disabled={disabled}
        max={data.max}
        min={data.min}
        value={value}
        onChange={(
          _event: Event,
          value: number | Array<number>,
          _activeThumb: number
        ) => setValue(value)}
        onChangeCommitted={(_e, newValue) => {
          const param = "value";
          data.setValue((prevState) => ({
            ...prevState,
            [param]: newValue,
          }));
        }}
        valueLabelDisplay="off"
        style={{ color: "#4a4266", opacity: disabled ? 0.3 : 1 }}
      />
    </div>
  );
}
