/* eslint-disable react-hooks/exhaustive-deps */
import { FilterContext } from "context-api/FilterContext";
import { FareRevenueFareBookingHistogramClient } from "lib/api/msd/fareRevenueFareBookingHistogram/fareRevenueFareBookingHistogram";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import styles from "./BookingsVsAverageFares.module.css";
import { ThemeContext } from "context-api/ThemeContext";

interface IBookingVsAverageFares {
  filterIsOpen: boolean;
}
interface IRangeValue {
  value: number | null;
  min: number;
  max: number;
  dateList: string[];
}
export default function BookingsVsAverageFares({
  filterIsOpen,
}: IBookingVsAverageFares) {
  const [isActive, setIsActive] = useState(false);
  const [rangeValue, setRangeValue] = useState<IRangeValue>({
    value: null,
    min: 0,
    max: 0,
    dateList: [],
  });
  const { filterList } = useContext(FilterContext);
  const [hostData, setHostData] = useState([]);
  const [hostLayout, setHostLayout] = useState<any>();
  const [compData, setCompData] = useState([]);
  const [compLayout, setCompLayout] = useState<any>();
  const [host, setHost] = useState<string>();
  const { theme } = useContext(ThemeContext);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setHost(JSON.parse(user).clientCode);
    }
  }, []);

  useEffect(() => {
    RangeSliderClient.fetchRangeSlider({
      agg_type: "monthly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        const param = ["max", "dateList", "value"];
        setRangeValue((prevState) => ({
          ...prevState,
          [param[0]]: response.values.length - 1,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
          [param[1]]: response.values,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
          [param[2]]: response.start_idx,
        }));
      })
      .catch(() => {
        console.log("Error");
      });
  }, []);

  useEffect(() => {
    if (!filterIsOpen && rangeValue.value !== null) {
      FareRevenueFareBookingHistogramClient.fetchFareRevenueFareBookingHistogram(
        {
          filterList,
          selected_yearmonth: moment(
            rangeValue.dateList[rangeValue.value]
          ).format("YYYY-MM"),
          view_opt: isActive ? "individual" : "cumulative",
          dark_theme: theme === "dark",
        }
      )
        .then((response: any) => {
          setHostData(response.fig_host.data);
          setCompData(response.fig_comp.data);
          setHostLayout(response.fig_host.layout);
          setCompLayout(response.fig_comp.layout);
          // setStoryText(response?.story_text);
        })
        .catch(() => {
          setHostData([]);
          setCompData([]);
        });
    }
  }, [filterIsOpen, filterList, isActive, rangeValue.value, theme]);

  return (
    <Card
      variant="secondary"
      title="Bookings vs Average Fares"
      height={552}
      // cardInfo={{
      //   title: storyText?.main_card?.title ?? null,
      //   description: storyText?.main_card?.content ?? null,
      // }}
    >
      <div className={styles.container}>
        <Card
          variant="primary"
          titlePosition="center"
          width={"100%"}
          height={400}
        >
          <h5 style={{ textAlign: "center" }}>{` ${host}`}</h5>
          <div className={styles.charts_wrapper}>
            <p className={styles.chart_title} data-theme={theme}>
              Total Passengers Booked
            </p>
            <Plotly
              data={hostData}
              height={320}
              layout={hostLayout}
              margin={{ t: 0, b: 20, l: 30, r: 0, p: 4 }}
            />
          </div>
        </Card>

        <Card
          variant="primary"
          titlePosition="center"
          width={"100%"}
          height={400}
        >
          <h5 style={{ textAlign: "center" }}>{` ${
            compLayout?.title?.text ?? ""
          }`}</h5>

          <div className={styles.charts_wrapper}>
            <p className={styles.chart_title}>Total Passengers Booked</p>
            <Plotly
              data={compData}
              height={320}
              layout={compLayout}
              margin={{ t: 0, b: 20, l: 30, r: 0, p: 4 }}
            />
          </div>
        </Card>
      </div>
      <div className={styles.range_and_button}>
        <RangeSlider
          data={{
            max: rangeValue.max,
            min: rangeValue.min,
            value: rangeValue.value ?? 0,
            dateList: rangeValue.dateList,
            setValue: setRangeValue,
          }}
        />
        <div className={styles.button_wrapper} data-theme={theme}>
          <span
            data-active={isActive}
            onClick={() => setIsActive(!isActive)}
            data-theme={theme}
          >
            Individual
          </span>
          <span
            data-active={!isActive}
            onClick={() => setIsActive(!isActive)}
            data-theme={theme}
          >
            Cumulative
          </span>
        </div>
      </div>
    </Card>
  );
}
