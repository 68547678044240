import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IBookingTrendsClient {
  filterList: IFilterContext;
  graphCarriers: string;
  time_direction: string;
  date_range_start: string;
  date_range_end: string;
  agg_type: string;
  holiday_countries: string;
  show_holidays: string;
  selected_holidays: string;
  dark_theme: boolean;
}

export class BookingTrendsClient {
  private static _client: RestClient;

  static fetchBookingTrendsClient({
    filterList,
    graphCarriers,
    time_direction,
    date_range_start,
    date_range_end,
    agg_type,
    holiday_countries,
    show_holidays,
    selected_holidays,
    dark_theme,
  }: IBookingTrendsClient) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return BookingTrendsClient.getClient().getCall(
      `/api/msdv2/events/pax-trends?graph_carriers=${graphCarriers}&time_direction=${time_direction}&date_range_start=${date_range_start}&date_range_end=${date_range_end}&show_holidays=${show_holidays}&agg_type=${agg_type}&holiday_countries=${holiday_countries}&selected_holidays=${selected_holidays}&${qp}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (BookingTrendsClient._client == null) {
      BookingTrendsClient._client = new RestClient();
    }
    return BookingTrendsClient._client;
  }
}
