import React, { useState, useEffect, useRef } from "react";
import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
} from "react-icons/fa";
import styles from "./styles.module.css";
import { CircularProgress } from "@mui/material";
import { TableColumn, TableProps, TableRow } from "./type";
import DraggableHeader from "./dnd";

const Table: React.FC<TableProps> = ({
  columns,
  data,
  pagination = false,
  paginationModel = undefined,
  serverMode = "client",
  loading = false,
  defaultPageSize = 10,
  tableHeight = "100%",
  selectableRows = false,
  nthChild = false,
  onRowClick = undefined,
  onRowSelect,
  onColumnOrderChange,
  onPaginationModelChange,
  onSortModelChange,
  onFilterModelChange,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageData, setCurrentPageData] = useState<TableRow[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [sortKey, setSortKey] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [currentColumns, setCurrentColumns] = useState<TableColumn[]>(columns);
  const [sortModel, setSortModel] = useState<
    { sortKey: string; sortOrder: "asc" | "desc" }[]
  >([]);

  const selectAllRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setCurrentColumns(columns);
  }, [columns]);
  useEffect(() => {
    if (pagination) {
      if (serverMode === "server") {
        setCurrentPageData(data);
        if (paginationModel) {
          setTotalItems(paginationModel.totalItems);
          setTotalPages(paginationModel.totalPages);
        }
      } else {
        let sortedData = [...data];
        if (sortKey && sortOrder) {
          sortedData.sort((a, b) => {
            if (a[sortKey] < b[sortKey]) return sortOrder === "asc" ? -1 : 1;
            if (a[sortKey] > b[sortKey]) return sortOrder === "asc" ? 1 : -1;
            return 0;
          });
        }
        setCurrentPageData(
          sortedData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        );
        setTotalItems(data.length);
        setTotalPages(Math.ceil(data.length / pageSize));
      }
    } else {
      let sortedData = [...data];
      if (sortKey && sortOrder) {
        sortedData.sort((a, b) => {
          if (a[sortKey] < b[sortKey]) return sortOrder === "asc" ? -1 : 1;
          if (a[sortKey] > b[sortKey]) return sortOrder === "asc" ? 1 : -1;
          return 0;
        });
      }
      setCurrentPageData(sortedData);
      setTotalItems(data.length);
      setTotalPages(1);
    }
  }, [
    currentPage,
    pageSize,
    sortKey,
    sortOrder,
    data,
    serverMode,
    pagination,
    paginationModel,
  ]);

  useEffect(() => {
    if (onRowSelect) {
      onRowSelect(selectedRows);
    }
  }, [selectedRows, onRowSelect]);

  useEffect(() => {
    const allData = serverMode === "server" ? currentPageData : data;
    if (selectAllRef.current) {
      const currentPageSelectedRows = allData.map((row) => row.id);
      const selectedCount = currentPageSelectedRows.filter((id) =>
        selectedRows.includes(id)
      ).length;
      if (selectedCount === 0) {
        selectAllRef.current.checked = false;
        selectAllRef.current.indeterminate = false;
        return;
      }
      if (selectedCount === allData.length) {
        selectAllRef.current.checked = true;
        selectAllRef.current.indeterminate = false;
      } else if (selectedCount > 0) {
        selectAllRef.current.checked = false;
        selectAllRef.current.indeterminate = true;
      } else {
        selectAllRef.current.checked = false;
        selectAllRef.current.indeterminate = false;
      }
    }
  }, [selectedRows, currentPageData, data, serverMode]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onPaginationModelChange({ page, pageSize });
  };
  const handleMultiSort = (sortModel) => {
    onSortModelChange(sortModel);
  };
  const handleSort = (key: string) => {
    if (sortKey === key) {
      if (sortOrder === "asc") {
        onSortModelChange([{ sortKey: key, sortOrder: "desc" }]);
        setSortModel([{ sortKey: key, sortOrder: "desc" }]);
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
        onSortModelChange([{ sortKey: key, sortOrder: "asc" }]);
        setSortModel([{ sortKey: key, sortOrder: "asc" }]);
      }
    } else {
      setSortKey(key);
      setSortOrder("asc");
      onSortModelChange([{ sortKey: key, sortOrder: "asc" }]);
      setSortModel([{ sortKey: key, sortOrder: "asc" }]);
    }
  };

  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPageSize(Number(event.target.value));
    onPaginationModelChange({ page: 1, pageSize: Number(event.target.value) });
    setCurrentPage(1);
  };

  const renderPaginationInfo = (): string => {
    const startItem = (currentPage - 1) * pageSize + 1;
    const endItem = Math.min(currentPage * pageSize, totalItems);
    return `Showing: ${startItem}-${endItem} of ${totalItems}`;
  };

  const renderPageNumbers = (): JSX.Element[] => {
    const pageNumbers: JSX.Element[] = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`${styles.paginationButton} ${
            currentPage === i ? styles.paginationActive : ""
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handleSelectAll = () => {
    if (serverMode === "server") {
      if (allSelected) {
        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.filter(
            (id) => !currentPageData.some((row) => row.id === id)
          )
        );
      } else {
        setSelectedRows((prevSelectedRows) => [
          ...prevSelectedRows,
          ...currentPageData
            .map((row) => row.id)
            .filter((id) => !prevSelectedRows.includes(id)),
        ]);
      }
    } else {
      if (allSelected) {
        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.filter((id) => !data.some((row) => row.id === id))
        );
      } else {
        setSelectedRows((prevSelectedRows) => [
          ...prevSelectedRows,
          ...data
            .map((row) => row.id)
            .filter((id) => !prevSelectedRows.includes(id)),
        ]);
      }
    }
    setAllSelected(!allSelected);
  };

  const handleRowSelectChange = (rowId: number) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowId)
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  const moveColumn = (dragIndex: number, hoverIndex: number) => {
    const updatedColumns = [...currentColumns];
    const [movedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(hoverIndex, 0, movedColumn);
    setCurrentColumns(updatedColumns);
    if (onColumnOrderChange) {
      onColumnOrderChange(updatedColumns);
    }
  };

  const isPaginationDisabled = totalPages <= 1 || currentPageData.length === 0;

  return (
    <>
      <div className={styles.tableContainer} style={{ height: tableHeight }}>
        {loading ? (
          <CircularProgress
            color="inherit"
            size={24}
            className={styles.progress}
          />
        ) : (
          <table className={styles.table}>
            <thead>
              <tr className={styles.tableHeaderRow}>
                {selectableRows && (
                  <th className={styles.tableHeaderCell}>
                    <input
                      type="checkbox"
                      ref={selectAllRef}
                      onChange={handleSelectAll}
                    />
                  </th>
                )}
                {currentColumns.map((column, index) =>
                  !column.hidden ? (
                    <DraggableHeader
                      key={column.key}
                      column={column}
                      allColumns={currentColumns}
                      index={index}
                      moveColumn={moveColumn}
                      onSort={handleSort}
                      onMultiSort={handleMultiSort}
                      sortKey={sortKey}
                      sortOrder={sortOrder}
                      sortModel={sortModel}
                      setSortModel={setSortModel}
                    />
                  ) : null
                )}
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((row, index) => (
                  <tr
                    key={index}
                    className={styles.tableBodyRow}
                    data-nth-child={nthChild}
                    onClick={() => onRowClick && onRowClick(row)}
                  >
                    {selectableRows && (
                      <td className={styles.tableBodyCell}>
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(row.id)}
                          onChange={() => handleRowSelectChange(row.id)}
                        />
                      </td>
                    )}
                    {currentColumns.map(
                      (column) =>
                        !column.hidden && (
                          <td
                            key={column.key}
                            className={styles.tableBodyCell}
                            style={{ width: column.width }}
                          >
                            {column.cell !== undefined
                              ? column.cell(row)
                              : row[column.key]}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={currentColumns.length + (selectableRows ? 1 : 0)}
                    style={{ textAlign: "center" }}
                  >
                    Data not found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {pagination && (
        <div className={styles.paginationContainer}>
          <span className={styles.paginationSpan}>
            {renderPaginationInfo()}
          </span>
          <select
            className={styles.pageSizeSelect}
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <button
            className={`${styles.paginationButton} ${
              currentPage === 1 || isPaginationDisabled
                ? styles.paginationButtonDisabled
                : ""
            }`}
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1 || isPaginationDisabled}
          >
            <FaAngleDoubleLeft size={18} />
          </button>
          <button
            className={`${styles.paginationButton} ${
              currentPage === 1 || isPaginationDisabled
                ? styles.paginationButtonDisabled
                : ""
            }`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1 || isPaginationDisabled}
          >
            <FaAngleLeft size={18} />
          </button>
          {renderPageNumbers()}
          <button
            className={`${styles.paginationButton} ${
              currentPage === totalPages || isPaginationDisabled
                ? styles.paginationButtonDisabled
                : ""
            }`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages || isPaginationDisabled}
          >
            <FaAngleRight size={18} />
          </button>
          <button
            className={`${styles.paginationButton} ${
              currentPage === totalPages || isPaginationDisabled
                ? styles.paginationButtonDisabled
                : ""
            }`}
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages || isPaginationDisabled}
          >
            <FaAngleDoubleRight size={18} />
          </button>
        </div>
      )}
    </>
  );
};

export default Table;
