import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IProductOverviewCabinMix {
  filterList: IFilterContext;
  agg_view: string;
  selected_yearmonth: string;
  dark_theme: boolean;
}

export class ProductOverviewCabinMixClient {
  private static _client: RestClient;

  static fetchProductOverviewCabinMix({
    filterList,
    agg_view,
    selected_yearmonth,
    dark_theme,
  }: IProductOverviewCabinMix) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return ProductOverviewCabinMixClient.getClient().getCall(
      `/api/msdv2/product-overview/cabin-mix?${qp}&agg_view=${agg_view}&selected_yearmonth=${selected_yearmonth}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (ProductOverviewCabinMixClient._client == null) {
      ProductOverviewCabinMixClient._client = new RestClient();
    }
    return ProductOverviewCabinMixClient._client;
  }
}
