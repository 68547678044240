import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import styles from "./styles.module.css";

interface IClassRBDBreakdown {
  data: {
    data: never[];
    layout: never[];
  };
  storyText: {
    bookings: {
      content: string;
      title: string;
    };
    class_rbd: {
      content: string;
      title: string;
    };
    days_to_departure: {
      content: string;
      title: string;
    };
    dow_revenue: {
      content: string;
      title: string;
    };
    fare_revenue: {
      content: string;
      title: string;
    };
    main_card: {
      content: string;
      title: string;
    };
    passenger_volume: {
      content: string;
      title: string;
    };
    ticket_type: {
      content: string;
      title: string;
    };
  };
}
export default function ClassRBDBreakdown({
  data,
  storyText,
}: IClassRBDBreakdown) {
  return (
    <Card
      variant="secondary"
      title="Class Mix"

      // cardInfo={{
      //   title: storyText?.class_rbd?.title ?? null,
      //   description: storyText?.class_rbd?.content ?? null,
      // }}
    >
      <Card variant="primary" titlePosition="center" height={400}>
        <div className={styles.chart_wrapper}>
          <Plotly
            data={data?.data}
            height={378}
            layout={data?.layout}
            margin={{ t: 20, b: 10, l: 10, r: 0, p: 4 }}
          />
        </div>
      </Card>
    </Card>
  );
}
