import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Fragment } from "react";
import styles from "./styles.module.css";
import { Tooltip } from "../Tooltip";

interface ICardInfo {
  title?: string | null;
  description?: string | JSX.Element | null;
  visible?: boolean;
}
const infoTooltip = ({ title, description }) => {
  const defaultTitle = "Lorem";
  const defaultDescription =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione, nostrum sapiente praesentium obcaecati quia minima quae asperiores quibusdam error odit mollitia ex voluptas laboriosam distinctio aspernatur tenetur. Beatae, voluptates autem.";
  return (
    <div className={styles.des_wrapper}>
      <h4>{title !== "" ? title ?? defaultTitle : defaultTitle}</h4>
      <p>
        {description !== ""
          ? description ?? defaultDescription
          : defaultDescription}
      </p>
    </div>
  );
};
export default function CardInfo({
  title,
  description,
  visible = false,
}: ICardInfo) {
  return (
    <Fragment>
      <Tooltip title={infoTooltip({ title, description })} arrow={true}>
        <span
          className={styles.container}
          style={{ display: visible ? "none" : "inline" }}
        >
          <InfoOutlinedIcon />
        </span>
      </Tooltip>
    </Fragment>
  );
}
