import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IFareStructureFareStructureTables {
  filterList: IFilterContext;
}

export class FareStructureFareStructureTablesClient {
  private static _client: RestClient;

  static fetchFareStructureFareStructureTables({
    filterList,
  }: IFareStructureFareStructureTables) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return FareStructureFareStructureTablesClient.getClient().getCall(
      `/api/msdv2/fare-structure/fare-structure-tables?${qp}`
    );
  }

  static getClient() {
    if (FareStructureFareStructureTablesClient._client == null) {
      FareStructureFareStructureTablesClient._client = new RestClient();
    }
    return FareStructureFareStructureTablesClient._client;
  }
}
