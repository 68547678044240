import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IFareRevenueFareBookingHistogram {
  filterList: IFilterContext;
  selected_yearmonth: string;
  view_opt: string;
  dark_theme: boolean;
}

export class FareRevenueFareBookingHistogramClient {
  private static _client: RestClient;

  static fetchFareRevenueFareBookingHistogram({
    filterList,
    selected_yearmonth,
    view_opt,
    dark_theme,
  }: IFareRevenueFareBookingHistogram) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return FareRevenueFareBookingHistogramClient.getClient().getCall(
      `/api/msdv2/fare-revenue/fare-booking-histogram?selected_yearmonth=${selected_yearmonth}&view_opt=${view_opt}&${qp}&dark_theme=${dark_theme}`
    );
  }

  static getClient() {
    if (FareRevenueFareBookingHistogramClient._client == null) {
      FareRevenueFareBookingHistogramClient._client = new RestClient();
    }
    return FareRevenueFareBookingHistogramClient._client;
  }
}
