import { useState } from "react";
import Layout from "components/common/Layout/Layout";
import LovestFareCalendar from "components/lfa/LovestFareCalendar/LovestFareCalendar";
import { DragAndDropComponent } from "./dnd";

export default function LOVESTFARECALENDAR() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<null | Date>(null);

  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Lowest Fare Calendar"
    >
      <div style={{ position: "relative" }}>
        <LovestFareCalendar
          filterIsOpen={filterIsOpen}
          setVisible={setVisible}
          setSelectedDate={setSelectedDate}
        />
        <div
          style={{
            display: visible ? "block" : "none",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        >
          <DragAndDropComponent
            setVisible={setVisible}
            selectedDate={selectedDate}
          />
        </div>
      </div>
    </Layout>
  );
}
