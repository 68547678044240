/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import RBDs from "../RBDs/RBDs";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import styles from "./ClassMix.module.css";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import { FareRevenueClassMixClient } from "lib/api/msd/fareRevenueClassMix/fareRevenueClassMix";
import { FilterContext } from "context-api/FilterContext";
import Plotly from "components/common/Charts/Plotly";
import { ThemeContext } from "context-api/ThemeContext";

interface IClassMix {
  filterIsOpen: boolean;
}
export default function ClassMix({ filterIsOpen }: IClassMix) {
  const [rangeValue, setRangeValue] = useState({
    value: 0,
    min: 1,
    max: 20,
    dateList: [],
  });
  const [host, setHost] = useState([]);
  const [comp, setComp] = useState([]);
  const { filterList } = useContext(FilterContext);
  const [layout, setLayout] = useState();
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    RangeSliderClient.fetchRangeSlider({
      agg_type: "monthly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        const param = ["max", "min", "dateList", "value"];
        setRangeValue((prevState) => ({
          ...prevState,
          [param[0]]: response.values.length - 1,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
          [param[1]]: 0,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
          [param[2]]: response.values,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
          [param[3]]: response.start_idx,
        }));
      })
      .catch(() => {
        console.log("Error");
      });
  }, []);

  useEffect(() => {
    if (!filterIsOpen && rangeValue.dateList.length > 0) {
      FareRevenueClassMixClient.fetchFareRevenueClassMix({
        filterList,
        selected_yearmonth: rangeValue.dateList[rangeValue.value],
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setLayout(response.fig_host.layout);
          setHost(response.fig_host.data);
          setComp(response.fig_comp.data);
          // setStoryText(response?.story_text);
        })
        .catch(() => {
          setHost([]);
          setComp([]);
        });
    }
  }, [filterIsOpen, filterList, rangeValue.value, theme]);
  return (
    <Card
      variant="secondary"
      title="Class Mix"
      height={581}
      // cardInfo={{
      //   title: storyText?.main_card?.title ?? null,
      //   description: storyText?.main_card?.content ?? null,
      // }}
    >
      <div className={styles.hosts_wrapper}>
        <RBDs data={host} title="Host’s RBDs:" />
        <RBDs data={comp} title="Competitors’s RBDs:" />
      </div>
      <div className={styles.charts_wrapper}>
        <Card
          variant="primary"
          titlePosition="center"
          width={"100%"}
          height={372}
        >
          <div className={styles.charts_container}>
            <p className={styles.chart_title}>Class Ratio (%)</p>
            <Plotly
              data={host}
              height={284}
              layout={layout}
              margin={{ t: 10, b: 20, l: 20, r: 0, p: 4 }}
            />
          </div>
        </Card>
        <Card
          variant="primary"
          titlePosition="center"
          width={"100%"}
          height={372}
        >
          <div className={styles.charts_container}>
            <p className={styles.chart_title}>Class Ratio (%)</p>
            <Plotly
              data={comp}
              height={284}
              layout={layout}
              margin={{ t: 10, b: 20, l: 20, r: 0, p: 4 }}
            />
          </div>
        </Card>
      </div>
      <div className={styles.range_wrapper}>
        <RangeSlider
          data={{
            max: rangeValue.max,
            min: rangeValue.min,
            value: rangeValue.value,
            dateList: rangeValue.dateList,
            setValue: setRangeValue,
          }}
        />
      </div>
    </Card>
  );
}
