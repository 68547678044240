import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IAgencyAnalysisAgencyTableClient {
  filterList: IFilterContext;
  comp_type: string;
  time_direction: string;
  agg_type: string;
  selected_yearmonth: string;
}
export class AgencyAnalysisAgencyTableClient {
  private static _client: RestClient;

  static fetchAgencyAnalysisAgencyTableClient({
    filterList,
    comp_type,
    time_direction,
    agg_type,
    selected_yearmonth,
  }: IAgencyAnalysisAgencyTableClient) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return AgencyAnalysisAgencyTableClient.getClient().getCall(
      `/api/msdv2/agency-analysis/agency-table?comp_type=${comp_type}&time_direction=${time_direction}&agg_type=${agg_type}&selected_yearmonth=${selected_yearmonth}&${qp}`
    );
  }

  static getClient() {
    if (AgencyAnalysisAgencyTableClient._client == null) {
      AgencyAnalysisAgencyTableClient._client = new RestClient();
    }
    return AgencyAnalysisAgencyTableClient._client;
  }
}
