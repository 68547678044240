import images from "constans/images";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import { useContext, useEffect, useState } from "react";
import styles from "./MainFilterClosed.module.css";
import Dropdown from "../Dropdown/Dropdown";
import { Room } from "@mui/icons-material";
import { Tooltip } from "../Tooltip";

interface IMainFilterClosed {
  isOpen: boolean;
  setIsOpen: any;
  currenciesDropdown: string[];
}

export default function MainFilterClosed({
  setIsOpen,
  currenciesDropdown,
}: IMainFilterClosed) {
  const [origin, setOrigin] = useState<string[]>([]);
  const [isOriginCity, setIsOriginCity] = useState<boolean>(false);
  const [destination, setDestination] = useState<string[]>([]);
  const [isDestinationCity, setIsDestinationCity] = useState<boolean>(false);

  const { filterList, setFilterList, options } = useContext(FilterContext);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (options && options.orig_city_airport) {
      let afterCityOrig: any = null;
      for (const city of options.orig_city_airport) {
        for (const airport of city?.airports) {
          if (filterList.origCityAirport.includes(airport?.airport_code)) {
            afterCityOrig = city;
          }
        }
      }
      if (
        afterCityOrig &&
        afterCityOrig?.airports?.length === filterList.origCityAirport.length
      ) {
        setOrigin([afterCityOrig?.code]);
        setIsOriginCity(true);
      } else {
        setOrigin(filterList.origCityAirport);
        setIsOriginCity(false);
      }
    }
    if (options && options.dest_city_airport) {
      let afterCityDest: any = null;
      for (const city of options.dest_city_airport) {
        for (const airport of city?.airports) {
          if (filterList.destCityAirport.includes(airport?.airport_code)) {
            afterCityDest = city;
          }
        }
      }

      if (
        afterCityDest &&
        afterCityDest?.airports?.length === filterList.destCityAirport.length
      ) {
        setDestination([afterCityDest?.code]);
        setIsDestinationCity(true);
      } else {
        setDestination(filterList.destCityAirport);
        setIsDestinationCity(false);
      }
    }
  }, [options, filterList]);

  return (
    <div className={styles.container} data-theme={theme}>
      <div className={styles.content}>
        <div className={styles.left} data-theme={theme}>
          <div className={styles.card}>
            <div className={styles.card_top}>
              <span className={styles.card_title} data-theme={theme}>
                Region
              </span>
              <img
                src={images.navigation_maps}
                alt=""
                className={styles.card_icon}
                data-theme={theme}
              />
            </div>
            <div className={styles.card_bottom}>
              <Tooltip
                title={`${filterList.origRegion.join()} to ${filterList.destRegion.join()}`}
              >
                <div className={styles.params_wrapper}>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text} data-theme={theme}>
                      {`${filterList.origRegion.join()}`}
                    </span>
                  </div>
                  <span className={styles.to} data-theme={theme}>
                    to
                  </span>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text} data-theme={theme}>
                      {`${filterList.destRegion.join()}`}
                    </span>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
          <span className={styles.hr}></span>
          <div className={styles.card}>
            <div className={styles.card_top}>
              <span className={styles.card_title} data-theme={theme}>
                Country
              </span>
              <img
                src={images.flag}
                alt=""
                className={styles.card_icon}
                data-theme={theme}
              />
            </div>
            <div className={styles.card_bottom}>
              <Tooltip
                title={`${filterList.origCountry.join()} to ${filterList.destCountry.join()}`}
              >
                <div className={styles.params_wrapper}>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text} data-theme={theme}>
                      {`${filterList.origCountry.join()}`}
                    </span>
                  </div>
                  <span className={styles.to} data-theme={theme}>
                    to
                  </span>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text} data-theme={theme}>
                      {`${filterList.destCountry.join()}`}
                    </span>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
          <span className={styles.hr}></span>
          <div className={styles.card}>
            <div className={styles.card_top}>
              <span className={styles.card_title} data-theme={theme}>
                City / Airport
              </span>
              <img
                src={images.travel_plane_take_off}
                alt=""
                className={styles.card_icon}
                data-theme={theme}
              />
            </div>
            <div className={styles.card_bottom}>
              <Tooltip
                title={`${filterList.origCityAirport.join()} to ${filterList.destCityAirport.join()}`}
              >
                <div className={styles.params_wrapper}>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text} data-theme={theme}>
                      {isOriginCity && <Room style={{ width: 16 }} />}{" "}
                      {`${origin.join()}`}
                    </span>
                  </div>
                  <span className={styles.to} data-theme={theme}>
                    to
                  </span>
                  <div className={styles.from_to_params}>
                    <span className={styles.card_text} data-theme={theme}>
                      {isDestinationCity && <Room style={{ width: 16 }} />}{" "}
                      {`${destination.join()}`}
                    </span>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.card}>
            <div className={styles.card_top}>
              <span className={styles.card_title} data-theme={theme}>
                Point of Sale
              </span>
            </div>
            <div className={styles.card_bottom}>
              <Tooltip title={`${filterList.pos.join()}`}>
                <div className={styles.from_to_params}>
                  <span className={styles.card_text} data-theme={theme}>
                    {`${filterList.pos.join()}`}
                  </span>
                </div>
              </Tooltip>
            </div>
          </div>
          <span className={styles.hr}></span>
          <div className={styles.card}>
            <div className={styles.card_top}>
              <span className={styles.card_title} data-theme={theme}>
                Competitors
              </span>
            </div>
            <div className={styles.card_bottom}>
              <Tooltip
                title={`${[
                  ...filterList.mainCompetitor,
                  ...filterList.selectedCompetitors,
                ].join()}`}
              >
                <div className={styles.from_to_params}>
                  <span className={styles.card_text} data-theme={theme}>
                    {`${[
                      ...filterList.mainCompetitor,
                      ...filterList.selectedCompetitors,
                    ].join()}`}
                  </span>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={styles.setting_button}>
            <img
              src={
                theme === "dark"
                  ? images.setting_button
                  : images.setting_button_light
              }
              alt=""
              onClick={() => {
                setIsOpen(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.card_footer} data-theme={theme}>
        <div className={styles.from_to_params}>
          <span className={styles.footer_title} data-theme={theme}>
            Sales Channel:
          </span>
          <Tooltip title={filterList.salesChannel.join()}>
            <span className={styles.footer_content} data-theme={theme}>
              {filterList.salesChannel.join()}
            </span>
          </Tooltip>
        </div>
        <div className={styles.from_to_params}>
          <span className={styles.footer_title} data-theme={theme}>
            Cabin:
          </span>
          <Tooltip title={filterList.cabin.join()}>
            <span className={styles.footer_content} data-theme={theme}>
              {filterList.cabin.join()}
            </span>
          </Tooltip>
        </div>
        <div className={styles.from_to_params}>
          <span className={styles.footer_title} data-theme={theme}>
            Pax Type:
          </span>
          <Tooltip title={filterList.paxType.join()}>
            <span className={styles.footer_content} data-theme={theme}>
              {filterList.paxType.join()}
            </span>
          </Tooltip>
        </div>
        <div>
          <div
            style={{
              width: 200,
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span className={styles.footer_title} data-theme={theme}>
              Currencies:
            </span>
            <Dropdown
              data={currenciesDropdown}
              added={filterList.currency ?? []}
              setAdded={(item) => {
                setFilterList({ ...filterList, currency: item });
              }}
              singleSelected
            />
          </div>
        </div>
      </div>
    </div>
  );
}
