import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications-component/dist/theme.css";
import "./styles/globals.css";

import { ThemeProvider } from "@mui/material";
import { CookiesProvider } from "react-cookie";
import { ConfigContextProvider } from "context-api/ConfigsContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReactNotifications } from "react-notifications-component";
import { FilterContextApp } from "context-api/FilterContext";
import { KPIContextApp } from "context-api/KPIContext";
import { LeftMenuContextApp } from "context-api/LeftMenu";
import { AvaibilityTrendsFilterContextApp } from "context-api/AvaibilityTrendsFilterContext";
import { ThemeContextApp } from "context-api/ThemeContext";
import { UserProfileContextApp } from "context-api/UserProfile";
import { ToastContainer } from "react-toastify";
import { DailyFlightsOverviewContextApp } from "context-api/DailyFlightsOverview";
import { AtarevProgressLinearContextApp } from "context-api/AtarevProgressLinear";
import { EAFilterContextApp } from "context-api/FilterContextEA";
import { FlightTypeSwitchApp } from "context-api/OwRtContext";
import MuiTheme from "theme";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={MuiTheme}>
      <DndProvider backend={HTML5Backend}>
        <CookiesProvider>
          <ConfigContextProvider>
            <ThemeContextApp>
              <KPIContextApp>
                <AvaibilityTrendsFilterContextApp>
                  <FilterContextApp>
                    <EAFilterContextApp>
                      <FlightTypeSwitchApp>
                        <LeftMenuContextApp>
                          <AtarevProgressLinearContextApp>
                            <DailyFlightsOverviewContextApp>
                              <ReactNotifications />
                              <UserProfileContextApp>
                                <App />
                              </UserProfileContextApp>
                              <ToastContainer theme="colored" />
                            </DailyFlightsOverviewContextApp>
                          </AtarevProgressLinearContextApp>
                        </LeftMenuContextApp>
                      </FlightTypeSwitchApp>
                    </EAFilterContextApp>
                  </FilterContextApp>
                </AvaibilityTrendsFilterContextApp>
              </KPIContextApp>
            </ThemeContextApp>
          </ConfigContextProvider>
        </CookiesProvider>
      </DndProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
