import { useEffect, useState } from "react";
import Layout from "components/common/Layout/Layout";
import Card from "components/common/Card/Card";
import Table from "components/common/Table/newTable";
import axios from "axios";

const columns = [
  {
    key: "departure_date",
    title: "Departure Date",
    width: "10%",
    sortable: true,
  },
  { key: "deptTime", title: "Dep Time", width: "10%", sortable: true },
  { key: "market", title: "Market", width: "40%", sortable: true },
  { key: "weekday", title: "Weekday", width: "40%", sortable: true },
];

export default function TestPage() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 10,
  });
  // const [filterModel, setFilterModel] = useState({ items: [] });
  const [sortModel, setSortModel] = useState<
    { sortKey: string; sortOrder: "asc" | "desc" }[]
  >([]);
  const [meta, setMeta] = useState({
    currentPage: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0,
  });

  const fetchData = async (
    paginationModel: {
      page: number;
      pageSize: number;
    },
    sortModel?: { sortKey: string; sortOrder: string }[],
    _filters?: Record<string, any>
  ) => {
    const params = {
      page_: paginationModel.page,
      limit_: paginationModel.pageSize,
      ...(sortModel &&
        sortModel?.length > 0 && { sort_: JSON.stringify(sortModel) }), // _sort varsa ekle
      ...(_filters && Object.keys(_filters).length > 0 && { ..._filters }), // _filters varsa ekle
    };
    try {
      const response = await axios.get("http://localhost:3000/api/data", {
        params,
      });
      setMeta(response?.data?.meta ?? {});
      return {
        data: response?.data?.data,
        totalItems: response?.data?.meta.totalItems,
        totalPages: response?.data?.meta.totalPages,
      };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return {
        data: [],
        totalItems: 0,
        totalPages: 0,
      };
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData(paginationModel, sortModel) // Başlangıçta sayfa 1 ve 10 veri getirme
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      });
  }, [paginationModel, sortModel]);

  return (
    <Layout
      title="Admin Panel"
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      filterHidden
    >
      <Card variant="secondary">
        <h2>Test Page</h2>
        <>
          <Table
            columns={columns}
            data={data}
            pagination
            paginationModel={meta}
            loading={isLoading}
            serverMode="server"
            defaultPageSize={10}
            tableHeight="500px"
            onRowSelect={(selectedRows) => {
              setSelectedRows(selectedRows);
            }}
            onColumnOrderChange={(newColumnOrder) => {
              console.log(
                "Yeni Kolon Sıralaması:",
                newColumnOrder.map((col) => col.key)
              );
            }}
            onPaginationModelChange={(newPaginationModel) => {
              // fetch data from server
              setPaginationModel(newPaginationModel);
            }}
            onSortModelChange={(newSortModel) => {
              // fetch data from server
              setSortModel(newSortModel);
            }}
            onFilterModelChange={(newFilterModel) => {
              // fetch data from server
            }}
          />
          <div>
            <h3>Selected Rows:</h3>
            <ul>
              {selectedRows.map((rowId) => (
                <li key={rowId}>Row ID: {rowId}</li>
              ))}
            </ul>
          </div>
        </>
      </Card>
    </Layout>
  );
}
