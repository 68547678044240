import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

export class DistanceAndDurationClient {
  private static _client: RestClient;

  static fetchDistanceAndDuration({ filterList }) {
    const qp = QueryParameterStringBuilder.apply(filterList);

    return DistanceAndDurationClient.getClient().getCall(
      `/api/msdv1/productOverview_distanceDuration?${qp}`
    );
  }

  static getClient() {
    if (DistanceAndDurationClient._client == null) {
      DistanceAndDurationClient._client = new RestClient();
    }
    return DistanceAndDurationClient._client;
  }
}
