/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Card from "components/common/Card/Card";
import Carriers from "components/common/Carriers/Carriers";
import Plotly from "components/common/Charts/Plotly";
import styles from "./styles.module.css";
import { StrategyActionsCompetitiveDiagnosticClient } from "lib/api/msd/strategyActionsCompetitiveDiagnostic/StrategyActionsCompetitiveDiagnostic";
import { FilterContext } from "context-api/FilterContext";
import MiniCard from "components/common/MiniCard/MiniCard";
import images from "constans/images";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import { ThemeContext } from "context-api/ThemeContext";

interface ICompetitivePositioning {
  filterIsOpen: boolean;
  data: any;
  setOtherDatas: any;
  storyText: {
    comp_environ: {
      content: string;
      title: string;
    };
    curr_month_perf: {
      content: string;
      title: string;
    };
    market_profile: {
      content: string;
      title: string;
    };
    rec_market_actions: {
      content: string;
      title: string;
    };
  };
  setStoryText: Dispatch<
    SetStateAction<{
      comp_environ: {
        content: string;
        title: string;
      };
      curr_month_perf: {
        content: string;
        title: string;
      };
      market_profile: {
        content: string;
        title: string;
      };
      rec_market_actions: {
        content: string;
        title: string;
      };
    }>
  >;
}
export default function CompetitivePositioning({
  filterIsOpen,
  data,
  setOtherDatas,
  storyText,
  setStoryText,
}: ICompetitivePositioning) {
  const [carriersJson, setCarriersJson] = useState<any>();
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [chartData, setChartData] = useState([]);
  const [layout, setLayout] = useState({});
  const { filterList } = useContext(FilterContext);
  const [fourCorners, setFourCorners] = useState({
    bottom_left: "",
    bottom_right: "",
    top_left: "",
    top_right: "",
  });
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (filterIsOpen === false) {
      StrategyActionsCompetitiveDiagnosticClient.fetchStrategyActionsCompetitiveDiagnostic(
        {
          filterList,
          graphCarriers: selectedCarriers.join(),
          dark_theme: theme === "dark",
        }
      )
        .then((response: any) => {
          setFourCorners({
            bottom_left: response.bottom_left,
            bottom_right: response.bottom_right,
            top_left: response.top_left,
            top_right: response.top_right,
          });
          setChartData(response.fig.data);
          setLayout(response.fig.layout);
          setOtherDatas(response);
          setCarriersJson(response?.carriers);
          setStoryText(response?.story_text);
        })
        .catch(() => {
          setChartData([]);
          setOtherDatas({
            market_details: {
              competitive_environment: [],
              market_profile: [],
            },
            metrics: {
              fare_gap: {
                title: "",
                value: "",
              },
              fare_variance: {
                title: "",
                value: "",
              },
              host_avg_fare: {
                title: "",
                value: "",
              },
              host_fair_share: {
                title: "",
                value: "",
              },
              host_pax: {
                title: "",
                value: "",
              },
              host_share: {
                title: "",
                value: "",
              },
              host_share_gap: {
                title: "",
                value: "",
              },
              industry_avg_fare: {
                title: "",
                value: "",
              },
              industry_pax: {
                title: "",
                value: "",
              },
              market_size: {
                title: "",
                value: "",
              },
            },
            revopt_fare_improvement: "",
            revopt_share_adjustment: "",
            strategy: {
              competitive_diagnostic: {
                title: "",
                value: [],
              },
              recommended_market_actions: {
                title: "",
                value: [],
              },
              recommended_strategy: {
                title: "",
                value: [],
              },
            },
          });
        });
    }
  }, [filterIsOpen, filterList, selectedCarriers, theme]);

  useEffect(() => {
    const x = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].find((item) => item === "All");
    const allParams = localStorage.getItem("allCompetitors");
    if (x === "All" && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
  }, [filterList.mainCompetitor, filterList.selectedCompetitors, filterIsOpen]);

  return (
    <div className={styles.container}>
      <Card
        variant="secondary"
        title="Current Month Performance"
        // cardInfo={{
        //   title: storyText?.curr_month_perf?.title ?? null,
        //   description: storyText?.curr_month_perf?.content ?? null,
        // }}
      >
        <div className={styles.carriers_wrapper}>
          <Carriers
            data={GetCarriers()}
            added={selectedCarriers}
            setAdded={setSelectedCarriers}
            carriersJson={carriersJson}
          />
        </div>
        <div className={styles.chart_and_metrics_wrapper}>
          <Card variant="primary" width={"100%"}>
            <div className={styles.chart_wrapper}>
              <span
                className={`${styles.chart_info} ${styles.top_left}`}
                data-theme={theme}
              >
                {fourCorners.top_left}
              </span>
              <span
                className={`${styles.chart_info} ${styles.top_right}`}
                data-theme={theme}
              >
                {fourCorners.top_right}
              </span>
              <span
                className={`${styles.chart_info} ${styles.bottom_left}`}
                data-theme={theme}
              >
                {fourCorners.bottom_left}
              </span>
              <span
                className={`${styles.chart_info} ${styles.bottom_right}`}
                data-theme={theme}
              >
                {fourCorners.bottom_right}
              </span>
              <Plotly
                data={chartData}
                layout={layout}
                margin={{ t: 60, b: 80, r: 60, l: 60, p: 4 }}
                displayModeBar="hover"
              />
            </div>
          </Card>
          <Card variant="primary">
            <div className={styles.detail}>
              <div className={styles.detail_card}>
                <span className={styles.icon_wrapper} data-active={"cyan"}>
                  <img
                    src={images.arrow_right}
                    alt=""
                    className={styles.card_icon}
                  />
                </span>
                <div>
                  <span className={styles.card_title_text}>
                    {data.metrics.fare_variance.title}
                  </span>
                  <div className={styles.text_and_graph_wrapper}>
                    <span>{data.metrics.fare_variance.value}</span>
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.detail_card}>
                <span className={styles.icon_wrapper} data-active={"purple"}>
                  <img
                    src={images.arrow_right}
                    alt=""
                    className={styles.card_icon}
                  />
                </span>
                <div>
                  <span className={styles.card_title_text}>
                    {data.metrics.fare_gap.title}
                  </span>
                  <div className={styles.text_and_graph_wrapper}>
                    <span>{data.metrics.fare_gap.value}</span>
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.detail_card}>
                <span className={styles.icon_wrapper} data-active={"blue"}>
                  <img
                    src={images.business_products_white}
                    alt=""
                    className={styles.card_icon}
                  />
                </span>
                <div>
                  <span className={styles.card_title_text}>
                    {data.metrics.host_fair_share.title}
                  </span>
                  <div className={styles.text_and_graph_wrapper}>
                    <span>{data.metrics.host_fair_share.value}</span>
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.detail_card}>
                <span className={styles.icon_wrapper} data-active={"red"}>
                  <img
                    src={images.business_products_white}
                    alt=""
                    className={styles.card_icon}
                  />
                </span>
                <div>
                  <span className={styles.card_title_text}>
                    {data.metrics.host_share_gap.title}
                  </span>
                  <div className={styles.text_and_graph_wrapper}>
                    <span>{data.metrics.host_share_gap.value}</span>
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Card>
      <div className={styles.mini_card_wrapper}>
        <MiniCard
          width={166}
          height={72}
          title={data?.metrics.market_size.title}
          total={data?.metrics.market_size.value}
          icon={images.business_chart_white}
          iconBackgroundColor={"#00ad92"}
          contentImage={""}
        />
        <MiniCard
          width={166}
          height={72}
          title={data?.metrics.host_pax.title}
          total={data?.metrics.host_pax.value}
          icon={images.group_user}
          iconBackgroundColor={"#da7706"}
          contentImage={""}
        />
        <MiniCard
          width={166}
          height={72}
          title={data?.metrics.host_share.title}
          total={data?.metrics.host_share.value}
          icon={images.business_products_white}
          iconBackgroundColor={"#ec4252"}
          contentImage={""}
        />
        <MiniCard
          width={166}
          height={72}
          title={data?.metrics.industry_pax.title}
          total={data?.metrics.industry_pax.value}
          icon={images.money_coins_white}
          iconBackgroundColor={"#d77cbb"}
          contentImage={""}
        />
        <MiniCard
          width={166}
          height={72}
          title={data?.metrics.industry_avg_fare.title}
          total={data?.metrics.industry_avg_fare.value}
          icon={images.money_coins_white}
          iconBackgroundColor={"#9c00ed"}
          contentImage={""}
        />
        <MiniCard
          width={166}
          height={72}
          title={data?.metrics.host_avg_fare.title}
          total={data?.metrics.host_avg_fare.value}
          icon={images.money_coins_white}
          iconBackgroundColor={"#144aff"}
          contentImage={""}
        />
      </div>
    </div>
  );
}
