import { useEffect } from "react";
import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import CustomAtarevTable from "components/common/Table/CustomTable";

interface ISchedulingDetails {
  filterIsOpen: boolean;
  schedulingTable: {
    data: never[];
    columns: never[];
  };
  storyText: {
    scheduling_comparison: {
      content: string;
      title: string;
    };
    scheduling_details: {
      content: string;
      title: string;
    };
  };
}

export default function SchedulingDetails({
  filterIsOpen,
  schedulingTable,
  storyText,
}: ISchedulingDetails) {
  useEffect(() => {}, [filterIsOpen]);
  return (
    <div className={styles.container}>
      <Card
        title="Scheduling Details"
        variant="secondary"
        height={544}
        // cardInfo={{
        //   title: storyText?.scheduling_details?.title ?? null,
        //   description: storyText?.scheduling_details?.content ?? null,
        // }}
      >
        <CustomAtarevTable
          data={schedulingTable.data}
          columns={schedulingTable.columns}
          pagination
          isShowing={false}
          height={250}
        />
      </Card>
    </div>
  );
}
