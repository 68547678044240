import { FilterContext } from "context-api/FilterContext";
import { FareRevenueDowRevenueClient } from "lib/api/msd/fareRevenueDowRevenue/fareRevenueDowRevenue";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import Carriers from "components/common/Carriers/Carriers";
import Plotly from "components/common/Charts/Plotly";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import styles from "./DaysOfWeekRevenueAnalysis.module.css";
import { ThemeContext } from "context-api/ThemeContext";

interface IDaysOfWeekRevenueAnalysis {
  filterIsOpen: boolean;
}

export default function DaysOfWeekRevenueAnalysis({
  filterIsOpen,
}: IDaysOfWeekRevenueAnalysis) {
  const [carriersJson, setCarriersJson] = useState<any>();
  const [rangeValue, setRangeValue] = useState({
    value: 0,
    min: 0,
    max: 20,
    dateList: [],
  });
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [chartData, setChartData] = useState([]);
  const [layout, setLayout] = useState([]);
  const [currency, setCurrency] = useState<string>("");
  const [storyText, setStoryText] = useState({
    main_card: {
      content: "",
      title: "",
    },
  });
  const { filterList } = useContext(FilterContext);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    RangeSliderClient.fetchRangeSlider({
      agg_type: "daily",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        const param = ["max", "min", "dateList", "value"];
        setRangeValue((prevState) => ({
          ...prevState,
          [param[0]]: response.values.length - 1,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
          [param[1]]: 0,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
          [param[2]]: response.values,
        }));
        setRangeValue((prevState) => ({
          ...prevState,
          [param[3]]: response.start_idx,
        }));
      })
      .catch(() => {
        console.log("Error");
      });
  }, []);

  useEffect(() => {
    if (!filterIsOpen && rangeValue.dateList.length > 0) {
      FareRevenueDowRevenueClient.fetchFareRevenueDowRevenue({
        filterList,
        graphCarriers: selectedCarriers.join(),
        selected_yearmonth: moment(
          rangeValue.dateList[rangeValue.value]
        ).format("YYYY-MM"),
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setCarriersJson(response?.carriers);
          setChartData(response.fig.data);
          setLayout(response.fig.layout);
          setStoryText(response?.story_text);
          setCurrency(response?.currency);
        })
        .catch(() => {
          setChartData([]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterIsOpen, filterList, selectedCarriers, rangeValue.value, theme]);

  useEffect(() => {
    const x = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].find((item) => item === "All");
    const allParams = localStorage.getItem("allCompetitors");
    if (x === "All" && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList.mainCompetitor, filterList.selectedCompetitors, filterIsOpen]);

  return (
    <Card
      title="Days of Week Revenue Analysis"
      variant="secondary"
      width={"100%"}
      height={439}
      cardInfo={{
        title: storyText?.main_card?.title ?? null,
        description: storyText?.main_card?.content ?? null,
      }}
    >
      <div className={styles.carriers_wrapper}>
        <Carriers
          data={GetCarriers()}
          added={selectedCarriers}
          setAdded={setSelectedCarriers}
          carriersJson={carriersJson}
        />
      </div>
      <div className={styles.container}>
        <Card variant="primary" height={303}>
          <div className={styles.charts_wrapper}>
            <p className={styles.chart_title}>Revenue ({currency})</p>
            <div style={{ height: 212 }}>
              <Plotly
                data={chartData}
                height={212}
                layout={layout}
                margin={{ t: 10, b: 50, l: 30, r: 10, p: 4 }}
              />
            </div>
          </div>
          <div className={styles.range_wrapper}>
            <RangeSlider
              data={{
                max: rangeValue.max,
                min: rangeValue.min,
                value: rangeValue.value,
                dateList: rangeValue.dateList,
                setValue: setRangeValue,
              }}
            />
          </div>
        </Card>
      </div>
    </Card>
  );
}
